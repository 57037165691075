<template>
  <div
    v-if="type !== 'course'"
    ref="menuver"
    :class="{
      menufixed: !device,
      menufixedmobile: device,
    }"
  >
    <nav
      id="sidebar"
      :style="{ height: height }"
      :class="{
        sidebar: !device,
        sidebarmobile: device,
        normalMenu: !styleMenu,
        mycourseMenu: styleMenu,
      }"
    >
      <ul
        id="lista"
        class="list-unstyled components"
        :class="{ minMenuUl: minMenu }"
        v-if="type === 'superuser'"
      >
        <li v-if="comunica === false" :class="{ minMenuLi: minMenu }">
          <router-link to="/adminsu/ccompany" :style="styleObject"
            ><i
              class="fas fa-building"
              :class="{ minMenuIcon: minMenu }"
              :data-title="$t(`menu.mcompany`)"
            ></i
          ></router-link>
        </li>
        <li v-if="comunica === false" :class="{ minMenuLi: minMenu }">
          <router-link to="/adminsu/superuser" :style="styleObject"
            ><i
              class="fas fa-user-plus"
              :class="{ minMenuIcon: minMenu }"
              :data-title="$t(`menu.msuperuser`)"
            ></i
          ></router-link>
        </li>
        <li v-if="comunica === false" :class="{ minMenuLi: minMenu }">
          <router-link to="/adminsu/mcountry" :style="styleObject"
            ><i
              class="fas fa-globe-europe"
              :class="{ minMenuIcon: minMenu }"
              :data-title="$t(`menu.countryMain`)"
            ></i
          ></router-link>
        </li>
        <li v-if="comunica === false" :class="{ minMenuLi: minMenu }">
          <router-link to="/adminsu/mlanguage" :style="styleObject"
            ><i
              class="fas fa-language"
              :class="{ minMenuIcon: minMenu }"
              :data-title="$t(`menu.mainLanguages`)"
            ></i
          ></router-link>
        </li>
        <li :class="{ minMenuLi: minMenu }">
          <router-link to="/adminsu/mcourse" :style="styleObject"
            ><i
              class="fas fa-graduation-cap"
              :class="{ minMenuIcon: minMenu }"
              :data-title="$t(`menu.mainCourses`)"
            ></i
          ></router-link>
        </li>
        <li :class="{ minMenuLi: minMenu }">
          <router-link to="/adminsu/mexams" :style="styleObject"
            ><i
              class="fas fa-book-reader"
              :class="{ minMenuIcon: minMenu }"
              :data-title="$t(`menu.mainExams`)"
            ></i
          ></router-link>
        </li>
        <li v-if="comunica === false" :class="{ minMenuLi: minMenu }">
          <router-link to="/adminsu/mcategories" :style="styleObject"
            ><i
              :class="{ minMenuIcon: minMenu }"
              class="fas fa-list"
              :data-title="$t(`menu.categories`)"
            ></i
          ></router-link>
        </li>
        <li v-if="comunica === false" :class="{ minMenuLi: minMenu }">
          <router-link to="/adminsu/mseo" :style="styleObject"
            ><i
              :class="{ minMenuIcon: minMenu }"
              class="fas fa-chart-line"
              :data-title="$t(`menu.seo`)"
            ></i
          ></router-link>
        </li>
        <li v-if="comunica === false" :class="{ minMenuLi: minMenu }">
          <router-link to="/adminsu/mcontact" :style="styleObject"
            ><i
              :class="{ minMenuIcon: minMenu }"
              class="fas fa-envelope-open-text"
              :data-title="$t(`menu.contact`)"
            ></i
          ></router-link>
        </li>
        <li v-if="comunica === false" :class="{ minMenuLi: minMenu }">
          <router-link to="/adminsu/mteachers" :style="styleObject">
            <i
              :class="{ minMenuIcon: minMenu }"
              class="fas fa-chalkboard-teacher"
              :data-title="$t(`menu.vprofesor`)"
            ></i>
          </router-link>
        </li>
      </ul>
      <ul id="lista" class="list-unstyled components" v-if="type === 'company'">
        <li>
          <router-link to="/admin/mylinks" :style="styleObject"
            ><i
              class="fas fa-external-link-alt"
              :data-title="$t(`menu.mylinks`)"
            ></i
          ></router-link>
        </li>
        <li>
          <router-link to="/admin/student" :style="styleObject"
            ><i class="fas fa-users" :data-title="$t(`menu.users`)"></i
          ></router-link>
        </li>
        <li>
          <router-link to="/admin/mcourse" :style="styleObject"
            ><i
              class="fas fa-graduation-cap"
              :data-title="$t(`menu.mainCourses`)"
            ></i
          ></router-link>
        </li>
        <li>
          <router-link to="/admin/mexams" :style="styleObject"
            ><i
              class="fas fa-book-reader"
              :data-title="$t(`menu.mainExams`)"
            ></i
          ></router-link>
        </li>
        <li>
          <router-link to="/admin/mteachers" :style="styleObject">
            <i
              :class="{ minMenuIcon: minMenu }"
              class="fas fa-chalkboard-teacher"
              :data-title="$t(`menu.vprofesor`)"
            ></i>
          </router-link>
        </li>
        <li>
          <router-link to="/admin/configuration" :style="styleObject"
            ><i class="fas fa-cogs" :data-title="$t(`menu.config`)"></i
          ></router-link>
        </li>
      </ul>
      <ul id="lista" class="list-unstyled components" v-if="type === 'user'">
        <li>
          <router-link :to="ruteMycourses" :style="styleObject"
            ><i class="fas fa-folder-open" :data-title="$t(`menu.courses`)"></i
          ></router-link>
        </li>
        <li>
          <router-link :to="ruteMyHistory" :style="styleObject"
            ><i class="fas fa-history" :data-title="$t(`menu.chistory`)"></i
          ></router-link>
        </li>
      </ul>
      <ul
        v-if="type === 'course'"
        class="list-group list-group"
        :style="device ? 'margin-top: 0pt' : 'margin-top: 50pt'"
      >
        <!-- -numbered -->
        <li
          v-for="(item, index) in content"
          :key="index"
          class="
            list-group-item
            d-flex
            justify-content-between
            align-items-start
          "
          :class="{
            active: selectedTheme === index,
          }"
          :style="activeThemes"
        >
          <div class="ms-2 me-auto">
            <div
              v-if="item.estado === 1"
              class="fw-bold"
              style="text-align: left"
              @click="selectTheme(item, index)"
            >
              {{ item.tema_titulo }}&nbsp;&nbsp;<i
                class="fas fa-arrow-left"
                data-title="ACTUAL"
                style="vertical-align: middle; font-size: 20pt"
              ></i>
            </div>
            <div
              v-if="item.estado === 0"
              class="fw-bold"
              style="text-align: left"
            >
              {{ item.tema_titulo }}
            </div>
            <div
              v-if="item.estado === 2"
              class="fw-bold"
              style="text-align: left"
              @click="selectTheme(item, index)"
            >
              {{ item.tema_titulo }}&nbsp;&nbsp;<i
                class="fas fa-clipboard-check"
                :data-title="$t(`menu.hecho`)"
                style="vertical-align: top; font-size: 20pt"
              ></i>
            </div>
          </div>
        </li>
        <li
          class="
            list-group-item
            d-flex
            justify-content-between
            align-items-start
          "
          :class="{
            active: selectedTheme === 'material',
          }"
          :style="activeThemes"
          @click="seematerial()"
        >
          <div class="ms-2 me-auto">
            {{ $t("mUser.course.material") }}
          </div>
        </li>
        <button
          v-if="profesor && estado_curso === 1"
          class="btn buttonProfe"
          @click="callProfesor"
        >
          {{ $t("actions.btnProfesor") }}
        </button>

        <button
          v-if="estado_curso === 2 && verValoracion"
          class="btn-success buttonProfe"
          @click="valorarCurso(item)"
          style="margin-top: 0"
        >
          {{ $t("mUser.course.valCourse") }}
        </button>
      </ul>
      <ul
        v-if="type === 'profesor'"
        class="list-group list-group"
        :style="device ? 'margin-top: 0pt' : 'margin-top: 50pt'"
      >
        <button
          style="margin-top: 0pt"
          class="btn-success buttonListProfe"
          @click="goToExamsProfe(0)"
        >
          {{ $t("profesor.exams") }}
        </button>
        <button class="btn-success buttonListProfe" @click="goToExamsProfe(1)">
          {{ $t("profesor.comisiones") }}
        </button>
        <li
          style="font-size: 17pt; cursor: default"
          class="
            list-group-item
            d-flex
            justify-content-between
            align-items-start
          "
        >
          {{ $t("profesor.courses") }}
        </li>
        <!-- -numbered -->
        <li
          v-for="(item, index) in content"
          :key="index"
          class="
            list-group-item
            d-flex
            justify-content-between
            align-items-start
          "
          :class="{
            active: selectedTheme === index,
          }"
          :style="activeThemes"
        >
          <div class="ms-2 me-auto">
            <div class="fw-bold" @click="selectTheme(item, index)">
              {{ index + 1 }} - {{ item.curso_titulo }}
            </div>
          </div>
        </li>
      </ul>
    </nav>
  </div>
  <div
    v-else
    ref="menuver"
    :class="{
      menufixed2: !device,
      menufixedmobile2: device,
    }"
  >
    <nav
      id="sidebar2"
      :style="{ height: height }"
      :class="{
        sidebar2: !device,
        sidebarmobile2: device,
        normalMenu: !styleMenu,
        mycourseMenu: styleMenu,
      }"
    >
      <ul
        v-if="type === 'course'"
        class="list-group list-group"
        :style="device ? 'margin-top: 0pt' : 'margin-top: 50pt'"
      >
        <!-- -numbered -->
        <li
          v-for="(item, index) in content"
          :key="index"
          class="
            list-group-item2
            d-flex
            justify-content-between
            align-items-start
          "
          :class="{
            active: selectedTheme === index,
          }"
          :style="activeThemes"
        >
          <div class="ms-2 me-auto">
            <div
              v-if="item.estado === 1"
              style="text-align: left"
              @click="selectTheme(item, index)"
            >
              {{ item.tema_titulo }}&nbsp;&nbsp;<i
                class="fas fa-arrow-left"
                data-title="ACTUAL"
                style="vertical-align: middle; font-size: 20pt; color: #ff0000"
              ></i>
            </div>
            <div
              v-if="item.estado === 0"
              style="text-align: left"
            >
              {{ item.tema_titulo }}
            </div>
            <div
              v-if="item.estado === 2"
              style="text-align: left"
              @click="selectTheme(item, index)"
            >
              {{ item.tema_titulo }}&nbsp;&nbsp;<i
                class="fas fa-clipboard-check"
                :data-title="$t(`menu.hecho`)"
                style="vertical-align: top; font-size: 20pt; color: #1a73e8"
              ></i>
            </div>
          </div>
        </li>
        <li
          class="
            list-group-item2
            d-flex
            justify-content-between
            align-items-start
          "
          :class="{
            active: selectedTheme === 'material',
          }"
          :style="activeThemes"
          @click="seematerial()"
        >
          <div class="ms-2 me-auto">
            {{ $t("mUser.course.material") }}
          </div>
        </li>
        <button
          v-if="profesor && estado_curso === 1"
          class="btn buttonProfe"
          @click="callProfesor"
        >
          {{ $t("actions.btnProfesor") }}
        </button>

        <button
          v-if="estado_curso === 2 && verValoracion"
          class="btn buttonProfe"
          @click="valorarCurso(item)"
          style="margin-top: 0"
        >
          {{ $t("mUser.course.valCourse") }}
        </button>
      </ul>
    </nav>
  </div>
  <div
  v-if="type === 'course' || type === 'profesor'"
    class="menu"
    :style="
      styleMenu        
        ? 'min-width: 400pt; max-width: 400pt'
        : 'min-width: 400pt; max-width: 400pt'
    "
  ></div>
  <div
    v-else
    class="menu2"
    :style="
      styleMenu
        ? 'min-width: 278pt; max-width: 153pt'
        : 'min-width: 153pt; max-width: 196pt'
    "
  ></div>

  <!-- Modal Valor -->
  <div
    class="modal fade"
    id="valorarModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("valor.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForm()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label" style="font-size: 20pt">{{
                titleCourse
              }}</label>
            </div>
            <label>{{ $t(`valor.puntos`) }}</label>
            <div class="mb-3" style="text-align: center">
              <div>
                <i
                  class="fas fa-crown crowns"
                  @click="tomarPunto(1)"
                  :style="{ color: colorCrown1 }"
                ></i>
                <i
                  class="fas fa-crown crowns"
                  @click="tomarPunto(2)"
                  :style="{ color: colorCrown2 }"
                ></i>
                <i
                  class="fas fa-crown crowns"
                  @click="tomarPunto(3)"
                  :style="{ color: colorCrown3 }"
                ></i>
                <i
                  class="fas fa-crown crowns"
                  @click="tomarPunto(4)"
                  :style="{ color: colorCrown4 }"
                ></i>
                <i
                  class="fas fa-crown crowns"
                  @click="tomarPunto(5)"
                  :style="{ color: colorCrown5 }"
                ></i>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`valor.comentario`) }}</label>
              <textarea
                style="text-transform: uppercase"
                rows="7"
                class="form-control"
                v-model.trim="comentario"
                maxlength="255"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="verValoracion"
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="sendValor"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForm()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModalMenu"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0'"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForm()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-else
            class="btn button btn-return-result"
            data-bs-target="#valorarModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "App",
  props: ["type", "name", "id_emp", "content", "profesor", "estado_curso"],
  emits: ["theme", "profesor", "material"],
  data() {
    return {
      height: "100%",
      ruteMyData: "",
      ruteMycourses: "",
      ruteMyHistory: "",
      ruteNotice: "",
      ruteSearch: "",
      selectedTheme: null,
      styleMenu: false,
      minMenu: false,
      comentario: "",
      puntos: "",
      colorCrown1: "#a4a9a8",
      colorCrown2: "#a4a9a8",
      colorCrown3: "#a4a9a8",
      colorCrown4: "#a4a9a8",
      colorCrown5: "#a4a9a8",
      curso_id: this.$route.params.id,
      result: "",
      verValoracion: true
    };
  },
  created() {
    if (this.type === "course" || this.type === "profesor") {
      this.styleMenu = true;
    }
    if (this.type === "profesor") {
      this.selectedTheme = this.professorId;
    }
    if (this.servidor.includes("comunica")) {
      this.verValoracion = false;
    }
    // console.log("ES COMUNICA", this.comunica);
  },
  computed: {
    ...mapState([
      "bigmenu",
      "colorCompany",
      "servidor",
      "device",
      "professorId",
      "comunica",
      "titleCourse",
    ]),
    styleObject() {
      this.getColorType();
      return {
        "--button-color--hover": this.colorCompany,
      };
    },
    activeThemes() {
      this.getColorType();
      // console.log("CAMBIA COLOR");
      if (this.type === "course") {
        return {
          "--color--active": '#1a73e8',
        };
      } else {
        return {
          "--color--active": this.colorCompany,
        };
      }
    },
  },
  mounted() {
    this.matchHeight();
    window.addEventListener("resize", this.matchHeight);
    this.changeCom();
  },
  unmounted() {
    window.removeEventListener("resize", this.matchHeight);
  },
  methods: {
    ...mapMutations([
      "changeColorCompanyGreen",
      "changeColorCompanyBlue",
      "changeColorCompanyRed",
      "changeColorCompanyOrange",
      "changeProfessorId",
    ]),
    mounted() {
      this.matchHeight();
      window.addEventListener("resize", this.matchHeight);
      this.changeCom();
    },
    unmounted() {
      window.removeEventListener("resize", this.matchHeight);
    },
    matchHeight() {
      const w = window.innerHeight - 205;
      const ul = document.getElementById("lista");

      if (w <= 450) {
        this.minMenu = true;
      } else {
        this.minMenu = false;
      }

      if (ul && ul.clientHeight >= w) {
        this.height = ul.clientHeight.toString() + "px";
      } else {
        if (w >= 400) {
          this.height = w.toString() + "px";
        }
      }
    },
    changeCom() {
      this.ruteMycourses = `/${this.id_emp}/student/mycourses`;
      this.ruteMyHistory = `/${this.id_emp}/student/myhistory`;
    },
    async getColorType() {
      // console.log("idemp", this.id_emp);
      try {
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?ac=color&id=${this.id_emp}`
        );
        // console.log(res);
        const color = res.data.emp_color;
        // console.log("COLOR", color);
        if (color === 0) {
          this.changeColorCompanyOrange();
        } else if (color === 1) {
          this.changeColorCompanyBlue();
        } else if (color === 2) {
          this.changeColorCompanyGreen();
        } else if (color === 3) {
          this.changeColorCompanyRed();
        }
      } catch (error) {
        console.error(error);
      }
    },
    selectTheme(item, index) {
      this.selectedTheme = index;
      if (this.type === "profesor") {
        this.changeProfessorId(index);
      }
      this.$emit("theme", item);
      if (this.type === "profesor") {
        this.$router.push({
          path: `/profesor/inicio`,
        });
      }
    },
    callProfesor() {
      this.selectedTheme = "";
      this.$emit("profesor");
    },
    seematerial() {
      this.selectedTheme = "material";
      this.$emit("material");
    },
    downloadCert() {
      this.selectedTheme = "";
      this.$emit("certificate");
    },
    goToExamsProfe(type) {
      // console.log("tipo", type);
      this.selectedTheme = null;
      if (type === 0) {
        this.$router.push({
          path: `/profesor/exams`,
        });
      } else if (type === 1) {
        this.$router.push({
          path: `/profesor/wallet`,
        });
      }
    },
    tomarPunto(num) {
      this.puntos = num;
      switch (num) {
        case 1:
          this.colorCrown1 = this.colorCompany;
          this.colorCrown2 = "#a4a9a8";
          this.colorCrown3 = "#a4a9a8";
          this.colorCrown4 = "#a4a9a8";
          this.colorCrown5 = "#a4a9a8";
          break;
        case 2:
          this.colorCrown1 = this.colorCompany;
          this.colorCrown2 = this.colorCompany;
          this.colorCrown3 = "#a4a9a8";
          this.colorCrown4 = "#a4a9a8";
          this.colorCrown5 = "#a4a9a8";
          break;
        case 3:
          this.colorCrown1 = this.colorCompany;
          this.colorCrown2 = this.colorCompany;
          this.colorCrown3 = this.colorCompany;
          this.colorCrown4 = "#a4a9a8";
          this.colorCrown5 = "#a4a9a8";
          break;
        case 4:
          this.colorCrown1 = this.colorCompany;
          this.colorCrown2 = this.colorCompany;
          this.colorCrown3 = this.colorCompany;
          this.colorCrown4 = this.colorCompany;
          this.colorCrown5 = "#a4a9a8";
          break;
        case 5:
          this.colorCrown1 = this.colorCompany;
          this.colorCrown2 = this.colorCompany;
          this.colorCrown3 = this.colorCompany;
          this.colorCrown4 = this.colorCompany;
          this.colorCrown5 = this.colorCompany;
          break;
      }
    },
    valorarCurso() {
      $("#valorarModal").modal("show");
    },
    async sendValor() {
      const valor = {
        curso_id: this.curso_id,
        encurso_comentario: this.comentario,
        encurso_valoracion: this.puntos,
      };
      // console.log("val", valor);
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=historial&ac=valorar`,
          valor
        );
        // console.log("valres", res);
        if (res.data) {
          this.result = res.data.RESULT;
        }
        $("#valorarModal").modal("hide");
        $("#responseModalMenu").modal("show");
      } catch (error) {
        $("#valorarModal").modal("hide");
        this.result = "1";
        $("#responseModalMenu").modal("show");
        console.error(error);
      }
    },
    cleanForm() {
      this.colorCrown1 = "#a4a9a8";
      this.colorCrown2 = "#a4a9a8";
      this.colorCrown3 = "#a4a9a8";
      this.colorCrown4 = "#a4a9a8";
      this.colorCrown5 = "#a4a9a8";
    },
  },
};
</script>

<style scoped>
.header {
  border-right: 1px solid #cccccc;
  margin-bottom: 6pt;
  padding: 26pt;
}

p {
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  background-color: white;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
}

.sidebar {
  min-width: 153pt;
  /* max-width: 196pt; */
  min-height: 573px;
  background: #696768;
  transition: all 0.3s;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.sidebar2 {
  min-width: 153pt;
  /* max-width: 196pt; */
  min-height: 573px;
  background: #dddddd;
  transition: all 0.3s;
  font-size: 16px;
  font-weight: normal;
}

.sidebarmobile {
  background: #696768;
  transition: all 0.3s;
  font-size: 16px;
  font-weight: bold;
  color: white;
  width: 100% !important;
  max-width: 100% !important;
}

.sidebarmobile2 {
  background: #dddddd;
  transition: all 0.3s;
  font-size: 16px;
  font-weight: normal;
  width: 100% !important;
  max-width: 100% !important;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #649be6;
}

#sidebar ul.components {
  padding: 25pt;
  font-size: 9pt;
  /* height: 100%; */
}

#sidebar ul p {
  padding: 10px;
  margin-left: 18px;
}

#sidebar ul li a {
  padding-top: 20pt;
  padding-bottom: 10pt;
  font-size: 1.1em;
  display: block;
}
#sidebar ul li a:hover {
  /* color: #e99417; */
  color: var(--button-color--hover);
}

.router-link-active,
.router-link-exact-active a {
  color: var(--button-color--hover);
}

li {
  text-align: center;
}

ul {
  margin-bottom: 0pt;
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #696768;
}

i {
  font-size: 29pt;
}

.menufixed {
  background-color: rgb(105, 103, 104);
  margin-top: 65pt;
  position: fixed;
  height: 100%;
  z-index: 50;
  /* overflow-y: scroll; */
}

.menufixedmobile {
  background-color: rgb(105, 103, 104);
  width: 100%;
}

.menufixed2 {
  background-color: #dddddd;
  margin-top: 35pt;
  position: fixed;
  height: 100%;
  z-index: 50;
  /* overflow-y: scroll; */
}

.menufixedmobile2 {
  background-color: #dddddd;
  width: 100%;
}

.menu {
  min-width: 153pt;
  max-width: 196pt;
}

.menu2 {
  min-width: 400pt;
  max-width: 400pt;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: white;
  text-decoration: none;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
}

.list-group-item2 {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  font-weight: normal;
}

.active {
  color: var(--color--active);
}

.minMenuLi {
  height: 50px;
}

.minMenuIcon {
  font-size: 20pt;
}

.minMenuUl {
  padding: 0px !important;
}

.mycourseMenu {
  width: 400pt;
  max-width: 400pt;
}

.normalMenu {
  width: 153pt;
  max-width: 196pt;
}

.buttonProfe {
  margin: 40pt;
  height: 35pt;
  color: #fff;
  background-color: #1a73e8;
  font-size:12pt;
  border-color: #fff;
}

.buttonListProfe {
  border-radius: 12pt;
  height: 35pt;
  background-color: cadetblue;
  border-color: cadetblue;
  margin: 10pt 10pt;
}
.crowns {
  font-size: 22pt;
  padding: 17pt;
  cursor: pointer;
}
</style>